html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  color: aliceblue;
  background-color: black;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.1rem;
  font-weight: 400;
}
